import { fetchUserSelections } from "../../../httpQueries/http";

interface Props {
  menu: {
    name: string;
    subcategory_id: string;
    menu_item_list: {
      count: number;
      name: string;
      price: number;
      qty_available: null;
      unit_size: null;
      unit_of_measurement: string;
      description: string;
      is_available: boolean;
      image: string;
      customizations: [
        {
          name: string;
          min_choice_options: number;
          max_choice_options: number;
          options: [
            {
              name: string;
              price: number;
              customizations: any[];
              min_qty: number;
              max_qty: number;
              conditional_price: {};
              formatted_price: string;
              default_qty: number;
              option_id: string;
            }
          ];
          customization_id: string;
        }
      ];
      min_price: number;
      original_price: number;
      formatted_price: string;
      attributes: [];
      product_id: string;
      thumbnail_image: string;
      should_fetch_customizations: boolean;
      supports_image_scaling: boolean;
    }[];
  }[];
  setPreOrder: React.Dispatch<
    React.SetStateAction<
      {
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number,
      }[]
    >
  >;
  mealBlockId: number | undefined;
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  agendaBlockId: number,
}

export async function loadUserSelections({
  setPreOrder,
  menu,
  mealBlockId,
  setOrders,
  agendaBlockId,
}: Props) {
  const token = localStorage.getItem('token');

  if (mealBlockId) {
    await fetchUserSelections({ id: mealBlockId, token }).then(userSelections => {
      setOrders(prevState => {
        const currentOrder = prevState.find(order => order.agendaBlockId === mealBlockId);
        console.log(currentOrder, prevState, mealBlockId, 'xx');
        
        const products: {
          price: number,
          id: string,
          qty: number,
          customizations: { customizationId: string; optionId: string[]; markedPrice: number; }[],
        }[] = [];

        userSelections?.mealSelectionsData.map(selection => menu.find(customization => {
          const product = customization.menu_item_list.find(item => item.name === selection.productName);

          if (product) {
            products.push({
              price: product.price,
              id: product.product_id,
              qty: selection.quantity,
              customizations: selection.customizations.map(custom => ({
                customizationId: custom.customizationId,
                optionId: custom.optionId, 
                markedPrice: custom.markedPrice,
              }))
            })
          }
        }))

        if (currentOrder) {
          return [...prevState.filter(order => order.agendaBlockId !== mealBlockId), ...products.map(product => ({
            agendaBlockId: mealBlockId,
            customizations: product.customizations,
            productId: product.id,
            markedPrice: product.price,
            notes: '',
            count: product.qty,
          }))];
        } else {
          return [...prevState, ...products.map(product => ({
            agendaBlockId: mealBlockId,
            customizations: product.customizations,
            productId: product.id,
            markedPrice: product.price,
            notes: '',
            count: product.qty,
          }))];
        }
      });
      setPreOrder(() => {
        const products: {
          markedPrice: number,
          notes: string,
          productId: string,
          count: number,
          customizations: { customizationId: string; optionId: string[]; markedPrice: number; }[],
        }[] = [];

        userSelections?.mealSelectionsData.map(selection => menu.find(customization => {
          const product = customization.menu_item_list.find(item => item.name === selection.productName);

          if (product) {
            products.push({
              markedPrice: product.price,
              productId: product.product_id,
              notes: '',
              count: selection.quantity,
              customizations: selection.customizations.map(custom => ({
                customizationId: custom.customizationId,
                optionId: custom.optionId, 
                markedPrice: custom.markedPrice,
              }))
            })
          }
        }));

        return products;
      })
    });
  }
}
