import TodoPreview from "../../../components/Todo/TodoPreview";
import { AttendeeRSVP } from "../../../types/attendeeRSVP";
import { TodoItem, TodoItemType } from "../../../types/todoItem";
import { markTodoAsCompleted } from "../../../utilities/markTodoAsCompleted";
import { openSelectMealsModal } from "./openSelectMealsModal";

interface Props {
  todoItems:
    | {
        todoItems: TodoItem[];
        total: number;
      }
    | undefined;
  onRSVPSelect(item: AttendeeRSVP): Promise<void>;
  setMealBlockId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNotLogginedUserIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEventAgendaMealBlock: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          count: number;
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[]
    >
  >;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close" | undefined;
      address: string;
      distance?: number | undefined;
      cuisines?: string[] | undefined;
      description: string;
    } | null>
  >;
  setMealBlockIdForOrder: React.Dispatch<React.SetStateAction<number>>;
  setCartId: React.Dispatch<React.SetStateAction<string | null>>;
  setAgendaBlockId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setOrderMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCopyAddressModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GetTodoItems({
  todoItems,
  onRSVPSelect,
  setMealBlockId,
  setSelectMealsModalIsOpen,
  setEventAgendaMealBlock,
  setNotLogginedUserIsOpen,
  setSelectedRestaurant,
  setMealBlockIdForOrder,
  setCartId,
  setOrderMealsModalIsOpen,
  setAgendaBlockId,
  setCopyAddressModalIsOpen
}: Props) {
  const token = localStorage.getItem("token");

  return todoItems?.todoItems
    .filter((todo) => !todo.isCompleted)
    .map((todo) => (
      <TodoPreview
        toDo={todo}
        onTodoClick={
          token
            ? todo.type === TodoItemType.MealSelection
              ? async () => await openSelectMealsModal({
                todo,
                setAgendaBlockId,
                setEventAgendaMealBlock,
                setMealBlockId,
                setSelectedRestaurant,
                setSelectMealsModalIsOpen,
                mealBlockId: todo.mealBlockId!,
              })
              : todo.type === TodoItemType.MealOrder
              ? async () => {
                  setMealBlockIdForOrder(todo.mealBlockId!);
                  setCartId(
                    todo.mealBlock?.cartId ? todo.mealBlock?.cartId : null
                  );
                  setCopyAddressModalIsOpen(true);
                  setOrderMealsModalIsOpen(true);
                  await markTodoAsCompleted(todo, token);
                }
              : todo.type === TodoItemType.RespondToInvite
              ? async (item: AttendeeRSVP | undefined) => {
                  if (item) {
                    await onRSVPSelect(item);
                    await markTodoAsCompleted(todo, token);
                  }
                }
              : async () => {}
            : async () => setNotLogginedUserIsOpen(true)
        }
        name={
          todo.type === TodoItemType.RespondToInvite
            ? "Event invite"
            : todo.type === TodoItemType.MealOrder
            ? "Meal order"
            : todo.type === TodoItemType.MealSelection
            ? "Meal selection"
            : todo.type === TodoItemType.SubmitEvent
            ? "Submit event"
            : todo.type === TodoItemType.ReviewDocument
            ? "Review document"
            : ""
        }
        description={
          todo.type === TodoItemType.RespondToInvite
            ? "Respond to the event invite"
            : todo.type === TodoItemType.MealOrder
            ? `Order meal for ${todo.event.name}`
            : todo.type === TodoItemType.MealSelection
            ? `Select meal for ${todo.event.name}`
            : todo.type === TodoItemType.SubmitEvent
            ? "Your event hasn’t been submitted."
            : todo.type === TodoItemType.ReviewDocument
            ? "Please, review the document"
            : ""
        }
        key={todo.id}
      />
    ));
}
