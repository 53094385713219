import React from "react";
import {
  fetchMeals,
  fetchRestaurantMenu,
  fetchSpecificRestaurant,
} from "../../../httpQueries/http";
import { MealBlockType } from "../../../types/meals";
import { markTodoAsCompleted } from "../../../utilities/markTodoAsCompleted";
import { TodoItem } from "../../../types/todoItem";

interface Props {
  todo?: TodoItem;
  setAgendaBlockId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close" | undefined;
      address: string;
      distance?: number | undefined;
      cuisines?: string[] | undefined;
      description: string;
    } | null>
  >;
  setEventAgendaMealBlock: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          count: number;
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[]
    >
  >;
  setMealBlockId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mealBlockId: number,
}

export async function openSelectMealsModal({
  todo,
  setAgendaBlockId,
  setSelectedRestaurant,
  setEventAgendaMealBlock,
  setMealBlockId,
  setSelectMealsModalIsOpen,
  mealBlockId
}: Props) {
  const token = localStorage.getItem("token");

  const mealBlock = await fetchMeals({
    id: mealBlockId,
    token,
  });

  if (mealBlock?.mealBlock?.restaurantId) {
    fetchRestaurantMenu({
      id: mealBlock.mealBlock.restaurantId,
      token,
      isPickup: mealBlock.mealBlock.type === MealBlockType.Pickup,
    }).then((menu) => {
      if (menu) {
        const preSelectedMeals: {
          name: string;
          subcategory_id: string;
          menu_item_list: {
            count: number;
            name: string;
            price: number;
            qty_available: null;
            unit_size: null;
            unit_of_measurement: string;
            description: string;
            is_available: boolean;
            image: string;
            customizations: [
              {
                name: string;
                min_choice_options: number;
                max_choice_options: number;
                options: [
                  {
                    name: string;
                    price: number;
                    customizations: any[];
                    min_qty: number;
                    max_qty: number;
                    conditional_price: {};
                    formatted_price: string;
                    default_qty: number;
                    option_id: string;
                  }
                ];
                customization_id: string;
              }
            ];
            min_price: number;
            original_price: number;
            formatted_price: string;
            attributes: [];
            product_id: string;
            thumbnail_image: string;
            should_fetch_customizations: boolean;
            supports_image_scaling: boolean;
          }[];
        }[] = [];

        const mealsIds = mealBlock.mealBlock.preSelectedMealsIds;

        if (mealsIds?.length) {
          menu.map((category) =>
            category.menu_item_list.map((product) => {
              if (mealsIds.includes(product.product_id)) {
                const preSelectedCategories = preSelectedMeals.map(
                  (item) => item.subcategory_id
                );
                if (preSelectedCategories.includes(category.subcategory_id)) {
                  const currentCategory = preSelectedMeals.find(
                    (item) => item.subcategory_id === category.subcategory_id
                  );

                  if (currentCategory) {
                    preSelectedMeals[
                      preSelectedMeals.findIndex(
                        (category) =>
                          category.subcategory_id ===
                          currentCategory.subcategory_id
                      )
                    ] = {
                      ...currentCategory,
                      menu_item_list: [
                        ...currentCategory.menu_item_list,
                        {
                          count: 0,
                          ...product,
                        },
                      ],
                    };
                  }
                } else {
                  preSelectedMeals.push({
                    ...category,
                    menu_item_list: [
                      {
                        count: 0,
                        ...product,
                      },
                    ],
                  });
                }
              }
            })
          );

          setEventAgendaMealBlock(preSelectedMeals);
        }
      }
    });

    const restaurant = await fetchSpecificRestaurant({
      id: mealBlock.mealBlock.restaurantId,
      token,
    });

    setSelectedRestaurant(
      restaurant
        ? {
            id: restaurant._id,
            name: restaurant.name,
            logo: restaurant.logo_photos[0],
            status: restaurant.is_open ? "Open" : "Close",
            address: restaurant.address.street_addr,
            distance: restaurant.miles,
            cuisines: restaurant.cuisines,
            description: restaurant.description,
          }
        : null
    );

    setMealBlockId(mealBlockId);
    setAgendaBlockId(mealBlock.mealBlock.agendaItem.id);
    setSelectMealsModalIsOpen(true);
  }

  if (todo) {
    await markTodoAsCompleted(todo, token);
  }
}
