import React, { useState } from "react";
import ReviewSelectionsModal from "../../../components/Modal/ReviewSelectionsModal";
import arrowRight from "../../../assets/images/chevron-right.png";
import arrowDown from "../../../assets/images/chevron-down-primary.svg";
import { Button } from "../../../components/Buttons/Button";

interface Props {
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  restaurantInfo: {
    name: string;
    address: string;
    cuisines: string;
    distance: string;
  };
  ordersCount: number;
  attendeesOrder: {
    attendeeName: string;
    selections: string[];
  }[];
  total: number;
  taxRate: number;
  onOrder: () => Promise<void>,
}

export default function ReviewSelectionsOrganizerView({
  title,
  setIsOpen,
  restaurantInfo,
  ordersCount,
  attendeesOrder,
  total,
  taxRate,
  onOrder
}: Props) {
  const [reviewSelectionsIsOpen, setReviewSelectionsIsOpen] = useState(false);

  return (
    <ReviewSelectionsModal title={`${title} meal`} setIsOpen={setIsOpen}>
      <div className="gap-16 flex flex-column">
        <div className="flex items-center spaceBetween gap-8">
          <h5 className="text-lg font-semibold color-gray-950">
            Order details
          </h5>
          <Button
            size={"md"}
            hierarchy={"linkColor"}
            buttonType={"regular"}
            paddingNone
          >
            Edit
          </Button>
        </div>
        <div className="flex flex-column gap-6">
          <h5 className="text-lg font-semibold color-gray-950">
            {restaurantInfo.name}
          </h5>
          <p className="text-sm font-regular color-gray-900">
            {restaurantInfo.address} • {restaurantInfo.distance} •{" "}
            {restaurantInfo.cuisines}
          </p>
        </div>
        <div className="flex flex-column gap-16">
          <div
            className="flex items-center spaceBetween gap-6"
            onClick={() => setReviewSelectionsIsOpen((prevState) => !prevState)}
          >
            <div className="flex items-center spaceBetween gap-16">
              <h5 className="text-lg font-semibold color-gray-950">
                Attendees order
              </h5>
              <p className="homePage-content-todos-header-topic-todosCount">
                {ordersCount}
              </p>
            </div>
            <img src={reviewSelectionsIsOpen ? arrowDown : arrowRight} alt="" />
          </div>
          {reviewSelectionsIsOpen ? (
            <div className="flex flex-column gap-16">
              {attendeesOrder.map((order) => (
                <div className="flex items-center spaceBetween gap-6">
                  <h6 className="text-lg font-regular color-gray-950">
                    {order.attendeeName}
                  </h6>
                  <p className="text-lg font-semibold color-gray-950">
                    {order.selections.join(", ")}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className="flex items-center spaceBetween gap-6">
          <p className="text-lg font-regular color-gray-950">
            Items ({attendeesOrder.length})
          </p>
          <p className="text-lg font-semibold color-gray-950">${total}</p>
        </div>
        <div className="flex items-center spaceBetween gap-6">
          <p className="text-lg font-regular color-gray-950">
            Tax ({taxRate}%)
          </p>
          <p className="text-lg font-semibold color-gray-950">
            ${total * (taxRate / 100)}
          </p>
        </div>
        <div className="fullWidth flex justifyEnd items-center mt-32">
          <Button
            buttonType="regular"
            hierarchy="newDesign-primary"
            size="md"
            contentCenter
            asyncOnClick={onOrder}
            newDesignPaddingNone
          >
            <p className="px-5">Finalize order</p>
          </Button>
        </div>
      </div>
    </ReviewSelectionsModal>
  );
}
