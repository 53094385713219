import React, { useContext } from "react";
import RSVP from "../../../components/RSVP/RSVP";
import {
  deleteEvent,
  publishEvent,
  fetchMeals,
  fetchRestaurantMenu,
  fetchSpecificRestaurant,
  finalizeMealBlock,
} from "../../../httpQueries/http";
import { MealBlockType } from "../../../types/meals";
import { TodoItem, TodoItemType } from "../../../types/todoItem";
import { markTodoAsCompleted } from "../../../utilities/markTodoAsCompleted";
import { Button } from "../../../components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { NotificationValue } from "../../../utilities/NotificationContext";
import { AttendeeRSVP } from "../../../types/attendeeRSVP";
import { useSelector } from "../../../store/hooks";
import { getRefetchEventsFunctionSelector } from "../../../store/events";

interface Props {
  isPublished: boolean;
  isOrganizer: boolean;
  slug: string;
  agendaLength: number;
  eventId: number;
  setPageView: React.Dispatch<React.SetStateAction<"attendee" | "organizer">>;
  setNotificationModalIsOpen: React.Dispatch<
    React.SetStateAction<{
      name: string;
      description: string;
      firstButtonName: string;
      secondButtonName: string;
      onFirstButtonClick: () => void;
      onSecondButtonClick: () => void;
    } | null>
  >;
  selectedRSVP: AttendeeRSVP | undefined;
  setSelectedRSVP: React.Dispatch<
    React.SetStateAction<AttendeeRSVP | undefined>
  >;
  onRSVPSelect(item: AttendeeRSVP): Promise<void>;
  todoItems:
    | {
        todoItems: TodoItem[];
        total: number;
      }
    | undefined;
  setNotLogginedUserIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEventAgendaMealBlock: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          count: number;
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[]
    >
  >;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close" | undefined;
      address: string;
      distance?: number | undefined;
      cuisines?: string[] | undefined;
      description: string;
    } | null>
  >;
  setMealBlockId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setAgendaBlockId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EventActions({
  isPublished,
  isOrganizer,
  slug,
  agendaLength,
  eventId,
  setPageView,
  setNotificationModalIsOpen,
  selectedRSVP,
  setSelectedRSVP,
  onRSVPSelect,
  todoItems,
  setNotLogginedUserIsOpen,
  setEventAgendaMealBlock,
  setSelectedRestaurant,
  setMealBlockId,
  setSelectMealsModalIsOpen,
  setAgendaBlockId
}: Props) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { setNotification } = useContext(NotificationValue);
  const refetchEvents = useSelector(getRefetchEventsFunctionSelector);

  return (
    <div className="reviewEventDetailsPage-actions">
      <h3 className="text-lg font-semibold color-gray-900">Event actions</h3>
      {isOrganizer ? (
        <div className="flex flex-column gap-8">
          {isPublished ? (
            <Button
              size={"md"}
              hierarchy={"newDesign-secondary"}
              buttonType={"regular"}
              fullWidth
              contentCenter
              newDesignPaddingNone
              onClick={() => {
                setPageView("attendee");
              }}
            >
              View as attendee
            </Button>
          ) : null}
          <div className="flex items-center gap-4">
            <Button
              size={"md"}
              hierarchy={"errorLinkGray"}
              buttonType={"regular"}
              fullWidth
              contentCenter
              newDesignPaddingNone
              onClick={() => {
                setNotificationModalIsOpen({
                  name: "Delete event",
                  description:
                    "Are you sure you want to delete your event? This action can’t be undone.",
                  firstButtonName: "Cancel",
                  secondButtonName: "Delete",
                  onFirstButtonClick: () => setNotificationModalIsOpen(null),
                  onSecondButtonClick: () => {
                    deleteEvent({
                      id: eventId,
                      token: token || "",
                    }).then(() => {
                      setNotification({
                        title: "Event has been deleted",
                        description:
                          "Your event has been successfully deleted.",
                        isError: false,
                      });
                      refetchEvents.refetch().then(() => navigate("/events?filter=organizer"));
                    });
                  },
                });
              }}
            >
              Delete
            </Button>
            <Button
              size={"sm"}
              hierarchy={
                isPublished ? "newDesign-primary" : "newDesign-secondary"
              }
              buttonType={"regular"}
              fullWidth
              contentCenter
              newDesignPaddingNone
              onClick={() => {
                navigate(`/edit/${slug}/details`);
              }}
            >
              Edit
            </Button>
            {!isPublished && agendaLength ? (
              <Button
                size={"sm"}
                hierarchy={"newDesign-primary"}
                buttonType={"regular"}
                fullWidth
                contentCenter
                newDesignPaddingNone
                asyncOnClick={async () => {
                  if (agendaLength) {
                    await publishEvent({
                      token,
                      eventId,
                    });
                    setNotification({
                      title: "Event has been updated",
                      description:
                        "Your event has been successfully updated and your invitees will be notified.",
                      isError: false,
                    });
                    navigate("/events?filter=organizer");
                  }
                }}
              >
                Publish
              </Button>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-16 flex-wrap">
          <RSVP
            selectedRSVP={selectedRSVP}
            setSelectedRSVP={token ? setSelectedRSVP : () => {}}
            onRSVPSelect={onRSVPSelect}
          />
          {todoItems?.todoItems &&
          todoItems.todoItems.find(
            (todo) =>
              todo.type === TodoItemType.MealSelection && !todo.isCompleted
          ) ? (
            <Button
              size={"md"}
              hierarchy={"newDesign-secondary"}
              buttonType={"regular"}
              contentCenter
              newDesignPaddingNone
              disabled={
                !todoItems?.todoItems.find(
                  (todo) => todo.type === TodoItemType.MealSelection
                )
              }
              asyncOnClick={async () => {
                if (!token) {
                  setNotLogginedUserIsOpen(true);

                  return;
                }

                const todo = todoItems?.todoItems.find(
                  (todo) => todo.type === TodoItemType.MealSelection
                );

                if (!todo) return;

                const mealBlock = await fetchMeals({
                  id: todo.mealBlockId!,
                  token,
                });

                if (mealBlock?.mealBlock?.restaurantId) {
                  fetchRestaurantMenu({
                    id: mealBlock.mealBlock.restaurantId,
                    token,
                    isPickup: mealBlock.mealBlock.type === MealBlockType.Pickup,
                  }).then((menu) => {
                    if (menu) {
                      const preSelectedMeals: {
                        name: string;
                        subcategory_id: string;
                        menu_item_list: {
                          count: number;
                          name: string;
                          price: number;
                          qty_available: null;
                          unit_size: null;
                          unit_of_measurement: string;
                          description: string;
                          is_available: boolean;
                          image: string;
                          customizations: [
                            {
                              name: string;
                              min_choice_options: number;
                              max_choice_options: number;
                              options: [
                                {
                                  name: string;
                                  price: number;
                                  customizations: any[];
                                  min_qty: number;
                                  max_qty: number;
                                  conditional_price: {};
                                  formatted_price: string;
                                  default_qty: number;
                                  option_id: string;
                                }
                              ];
                              customization_id: string;
                            }
                          ];
                          min_price: number;
                          original_price: number;
                          formatted_price: string;
                          attributes: [];
                          product_id: string;
                          thumbnail_image: string;
                          should_fetch_customizations: boolean;
                          supports_image_scaling: boolean;
                        }[];
                      }[] = [];

                      const mealsIds = mealBlock.mealBlock.preSelectedMealsIds;

                      if (mealsIds?.length) {
                        menu.map((category) =>
                          category.menu_item_list.map((product) => {
                            if (mealsIds.includes(product.product_id)) {
                              const preSelectedCategories =
                                preSelectedMeals.map(
                                  (item) => item.subcategory_id
                                );
                              if (
                                preSelectedCategories.includes(
                                  category.subcategory_id
                                )
                              ) {
                                const currentCategory = preSelectedMeals.find(
                                  (item) =>
                                    item.subcategory_id ===
                                    category.subcategory_id
                                );

                                if (currentCategory) {
                                  preSelectedMeals[
                                    preSelectedMeals.findIndex(
                                      (category) =>
                                        category.subcategory_id ===
                                        currentCategory.subcategory_id
                                    )
                                  ] = {
                                    ...currentCategory,
                                    menu_item_list: [
                                      ...currentCategory.menu_item_list,
                                      {
                                        count: 0,
                                        ...product,
                                      },
                                    ],
                                  };
                                }
                              } else {
                                preSelectedMeals.push({
                                  ...category,
                                  menu_item_list: [
                                    {
                                      count: 0,
                                      ...product,
                                    },
                                  ],
                                });
                              }
                            }
                          })
                        );

                        setEventAgendaMealBlock(preSelectedMeals);
                      }
                    }
                  });

                  const restaurant = await fetchSpecificRestaurant({
                    id: mealBlock.mealBlock.restaurantId,
                    token,
                  });

                  setSelectedRestaurant(
                    restaurant
                      ? {
                          id: restaurant._id,
                          name: restaurant.name,
                          logo: restaurant.logo_photos[0],
                          status: restaurant.is_open ? "Open" : "Close",
                          address: restaurant.address.street_addr,
                          distance: restaurant.miles,
                          cuisines: restaurant.cuisines,
                          description: restaurant.description,
                        }
                      : null
                  );

                  setMealBlockId(todo.mealBlockId);
                  setAgendaBlockId(mealBlock.mealBlock.agendaItem.id);
                  setSelectMealsModalIsOpen(true);
                }

                await markTodoAsCompleted(todo, token);
              }}
            >
              Order food
            </Button>
          ) : null}
        </div>
      )}
    </div>
  );
}
