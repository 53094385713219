import React, { useEffect, useState } from "react";
import RestaurantSelect from "./RestaurantSelect/RestaurantSelect";
import RestaurantMenu from "./MealSelect/RestaurantMenu";
import { fetchMeals, fetchRestaurantMenu, fetchSpecificRestaurant, fetchUserSelections } from "../../httpQueries/http";
import { useWindowSize } from "@uidotdev/usehooks";
import { MealBlockChoice, MealBlockType } from "../../types/meals";

interface Props {
  longitude: number;
  latitude: number;
  location: string;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close";
      address: string;
      distance?: number;
      cuisines?: string[];
      menuLink?: string,
    } | null>
  >;
  setShowSelectRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close";
    address: string;
    distance?: number;
    cuisines?: string[];
    menuLink?: string,
  } | null;
  onAddBlock: () => void;
  setCreatorIsOrder: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        state: boolean | null;
      }[]
    >
  >;
  creatorIsOrder: {
    id: number;
    state: boolean | null;
  }[];
  dayId: number;
  agendaBlockStartTime: number;
  showSelectRestaurantModal: boolean;
  setOrderedMeals: React.Dispatch<
    React.SetStateAction<
      {
        customizations: {
          customizationId: string;
          optionId: string;
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
      }[]
    >
  >;
  orderedMeals: {
    customizations: {
      customizationId: string;
      optionId: string;
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
  }[];
  setIsPickup: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        state: boolean;
      }[]
    >
  >;
  isPickup: {
    id: number;
    state: boolean;
  }[];
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  setSelectedAvailableMealsForAttendees: React.Dispatch<
    React.SetStateAction<{ id: number; products: {id: string, name: string}[] }[]>
  >;
  selectedAvailableMealsForAttendees: { id: number; products: {id: string, name: string}[] }[];
  agendaBlockId: number;
  restaurantsForOrders: {
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[];
  setRestaurantsForOrders: React.Dispatch<
    React.SetStateAction<
      {
        id: string | number;
        name: string;
        status?: "Open" | "Close" | undefined;
        logo: string;
        address: string;
        distance?: number | undefined;
        cuisines?: string[] | undefined;
        agendaBlockId: number;
        menuLink?: string,
      }[]
    >
  >;
  mealBLockId?: number;
  setIsLoadingMealBlock: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SelectMeals({
  location,
  longitude,
  latitude,
  setSelectedRestaurant,
  setShowSelectRestaurantModal,
  selectedRestaurant,
  onAddBlock,
  setCreatorIsOrder,
  creatorIsOrder,
  dayId,
  agendaBlockStartTime,
  showSelectRestaurantModal,
  orderedMeals,
  setOrderedMeals,
  setIsPickup,
  isPickup,
  setOrders,
  orders,
  selectedAvailableMealsForAttendees,
  setSelectedAvailableMealsForAttendees,
  agendaBlockId,
  restaurantsForOrders,
  setRestaurantsForOrders,
  mealBLockId,
  setIsLoadingMealBlock
}: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const token = localStorage.getItem("token");
  const [currentStep, setCurrentStep] = useState<"Restaurant" | "Meals">(
    "Restaurant"
  );
  const [fee, setFee] = useState(3.0);
  const [totalSum, setTotalSum] = useState(fee);
  const [isLoading, setIsLoading] = useState(false);
  const [searchRestaurantValue, setSearchRestaurantValue] = useState("");
  const [menu, setMenu] = useState<
    {
      name: string;
      subcategory_id: string;
      menu_item_list: {
        count: number;
        name: string;
        price: number;
        qty_available: null;
        unit_size: null;
        unit_of_measurement: string;
        description: string;
        is_available: boolean;
        image: string;
        customizations: [
          {
            name: string;
            min_choice_options: number;
            max_choice_options: number;
            options: [
              {
                name: string;
                price: number;
                customizations: any[];
                min_qty: number;
                max_qty: number;
                conditional_price: {};
                formatted_price: string;
                default_qty: number;
                option_id: string;
              }
            ];
            customization_id: string;
          }
        ];
        min_price: number;
        original_price: number;
        formatted_price: string;
        attributes: [];
        product_id: string;
        thumbnail_image: string;
        should_fetch_customizations: boolean;
        supports_image_scaling: boolean;
      }[];
    }[]
  >([]);
  const [fetchedFromMealBlock, setFetchedFromMealBlock] = useState(false);

  useEffect(() => {
    if (mealBLockId) {
      fetchUserSelections({ id: mealBLockId, token }).then(userSelections => {
        setOrders(prevState => {
          const currentOrder = prevState.find(order => order.agendaBlockId === agendaBlockId);
          const products: {
            price: number,
            id: string,
            qty: number,
            customizations: { customizationId: string; optionId: string[]; markedPrice: number; }[],
          }[] = [];

          userSelections?.mealSelectionsData.map(selection => menu.find(customization => {
            const product = customization.menu_item_list.find(item => item.name === selection.productName);

            if (product) {
              products.push({
                price: product.price,
                id: product.product_id,
                qty: selection.quantity,
                customizations: selection.customizations.map(custom => ({
                  customizationId: custom.customizationId,
                  optionId: custom.optionId, 
                  markedPrice: custom.markedPrice,
                }))
              })
            }
          }))

          if (currentOrder) {
            return [...prevState.filter(order => order.agendaBlockId === agendaBlockId), ...products.map(product => ({
              agendaBlockId,
              customizations: product.customizations as { customizationId: string; optionId: string[]; markedPrice: number; }[],
              productId: product.id,
              markedPrice: product.price,
              notes: '',
              count: product.qty,
            }))];
          } else {
            return [...prevState, ...products.map(product => ({
              agendaBlockId,
              customizations: product.customizations as { customizationId: string; optionId: string[]; markedPrice: number; }[],
              productId: product.id,
              markedPrice: product.price,
              notes: '',
              count: product.qty,
            }))];
          }
        });
      });
    }
  }, [mealBLockId])

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    if (fetchedFromMealBlock) {
      setFetchedFromMealBlock(true);
    }

    if (selectedRestaurant && typeof selectedRestaurant.id === "string" && !fetchedFromMealBlock) {
      setIsLoading(true);
      fetchRestaurantMenu({
        id: selectedRestaurant.id,
        token,
        isPickup: !!isPickup.find((it) => it.id === agendaBlockId)?.state,
      }).then((res) => {
        const menuItems = res?.map((category) => ({
          ...category,
          menu_item_list: category.menu_item_list.map((meal) => ({
            count: 0,
            ...meal,
          })),
        }));

        setMenu(_prevState => menuItems || []);
        setIsLoading(false);
      }).finally(() =>  setIsLoading(false));

      if (creatorIsOrder) {
        setSelectedAvailableMealsForAttendees((prevState) =>
          prevState.filter((item) => item.id !== agendaBlockId)
        );
      }
    }
  }, [creatorIsOrder]);

  useEffect(() => {
    setTotalSum(fee);
    setOrders((prevState) => [
      ...prevState.filter((item) => item.agendaBlockId !== agendaBlockId),
    ]);
  }, [currentStep, creatorIsOrder]);

  useEffect(() => {
    if (currentStep === "Restaurant") {
      setMenu([]);
    }
  }, [currentStep]);

  useEffect(() => {
    if (showSelectRestaurantModal) {
      setSearchRestaurantValue("");
    }
  }, [showSelectRestaurantModal]);

  useEffect(() => {
    if (selectedRestaurant) {
      setCurrentStep("Meals");
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (mealBLockId) {
      setIsLoadingMealBlock(true);
      fetchMeals({ id: mealBLockId, token }).then((response) => {
        if (response?.mealBlock.restaurantId) {
          fetchSpecificRestaurant({
            id: response?.mealBlock.restaurantId,
            token,
          }).then((restaurant) => {
            if (restaurant) {
              setFetchedFromMealBlock(true);
              setSelectedRestaurant(_prevState => ({
                id: restaurant?._id,
                name: restaurant?.name,
                logo: restaurant.logo_photos[0],
                status: restaurant.is_open ? "Open" : "Close",
                address: restaurant.address.street_addr,
                distance: restaurant.miles,
                cuisines: restaurant.cuisines,
              }));
              setRestaurantsForOrders((prevState) => {
                return [
                  ...prevState.filter(
                    (item) => item.agendaBlockId !== agendaBlockId
                  ),
                  {
                    id: restaurant?._id,
                    name: restaurant?.name,
                    logo: restaurant.logo_photos[0],
                    status: restaurant.is_open ? "Open" : "Close",
                    address: restaurant.address.street_addr,
                    distance: restaurant.miles,
                    cuisines: restaurant.cuisines,
                    agendaBlockId: agendaBlockId,
                  },
                ];
              });
              setIsPickup((prevState) => [
                ...prevState.filter((item) => item.id !== agendaBlockId),
                {
                  id: agendaBlockId,
                  state:
                    response.mealBlock.type === MealBlockType.Pickup
                      ? true
                      : false,
                },
              ]);
              setCreatorIsOrder((prevState) => [
                ...prevState.filter((item) => item.id !== agendaBlockId),
                {
                  id: agendaBlockId,
                  state:
                    response.mealBlock.choice === MealBlockChoice.Organizer
                      ? false
                      : true,
                },
              ]);

              fetchRestaurantMenu({
                id: restaurant._id,
                token,
                isPickup: response.mealBlock.type === MealBlockType.Pickup,
              }).then((res) => {
                const menuItems = res?.map((category) => ({
                  ...category,
                  menu_item_list: category.menu_item_list.map((meal) => ({
                    count: 0,
                    ...meal,
                  })),
                })) || [];

                const preSelectedMeals = response.mealBlock.preSelectedMealsIds?.map((id) => {
                  let productName = "";

                  menuItems.map((customization) => {
                    productName =
                      customization.menu_item_list.find(
                        (item) => item.product_id === id
                      )?.name || "";
                  });
  
                  return {
                    id,
                    name: productName,
                  };
                }) || [];
                setSelectedAvailableMealsForAttendees((prevState) => {
                  return [
                    ...prevState.filter((item) => item.id !== agendaBlockId),
                    {
                      id: agendaBlockId,
                      products: preSelectedMeals,
                    },
                  ];
                });

                setMenu(_prevState => menuItems.map((customization) => ({
                  ...customization,
                  menu_item_list: customization.menu_item_list.map((item) => ({
                    ...item,
                    count: preSelectedMeals?.map((item) => item.id)
                      .includes(item.product_id)
                      ? 1
                      : 0,
                  })),
                })));
              });
            }
          });
        }
        setIsLoadingMealBlock(false);
      });
    }
  }, [mealBLockId, agendaBlockId]);
  
  return (
    <div
      className={`selectMeals ${
        showSelectRestaurantModal ? "selectMeals-isOpen" : ""
      }`}
    >
      {currentStep === "Restaurant" ? (
        <RestaurantSelect
          latitude={latitude}
          location={location}
          longitude={longitude}
          setMenu={setMenu}
          setSelectedRestaurant={setSelectedRestaurant}
          setShowSelectRestaurantModal={setShowSelectRestaurantModal}
          setCurrentStep={setCurrentStep}
          setIsPickup={setIsPickup}
          isPickup={!!isPickup.find((it) => it.id === agendaBlockId)?.state}
          selectedRestaurant={selectedRestaurant}
          dayId={dayId}
          agendaBlockStartTime={agendaBlockStartTime}
          isDesktopSize={sizeIsDesktop}
          showSelectRestaurantModal={showSelectRestaurantModal}
          searchValue={searchRestaurantValue}
          setSearchValue={setSearchRestaurantValue}
          restaurantsForOrders={restaurantsForOrders}
          setRestaurantsForOrders={setRestaurantsForOrders}
          agendaBlockId={agendaBlockId}
          setCreatorIsOrder={setCreatorIsOrder}
          creatorIsOrder={
            typeof creatorIsOrder.find((it) => it.id === agendaBlockId)
              ?.state === "boolean"
              ? !!creatorIsOrder.find((it) => it.id === agendaBlockId)?.state
              : null
          }
        />
      ) : (
        <RestaurantMenu
          id={
            typeof selectedRestaurant!.id === "string"
              ? selectedRestaurant!.id
              : ""
          }
          name={selectedRestaurant!.name}
          setCurrentStep={setCurrentStep}
          isPickup={!!isPickup.find((it) => it.id === agendaBlockId)?.state}
          setTotalSum={setTotalSum}
          fee={fee}
          setMenu={setMenu}
          menu={menu}
          fetchedFromMealBlock={fetchedFromMealBlock}
          setFetchedFromMealBlock={setFetchedFromMealBlock}
          setShowSelectRestaurantModal={setShowSelectRestaurantModal}
          onAddBlock={onAddBlock}
          setCreatorIsOrder={setCreatorIsOrder}
          creatorIsOrder={
            !!creatorIsOrder.find((it) => it.id === agendaBlockId)?.state
          }
          currentStep={currentStep}
          orderedMeals={orderedMeals}
          setOrderedMeals={setOrderedMeals}
          selectedAvailableMealsForAttendees={
            selectedAvailableMealsForAttendees
          }
          setSelectedAvailableMealsForAttendees={
            setSelectedAvailableMealsForAttendees
          }
          setOrders={setOrders}
          orders={orders}
          selectedRestaurant={selectedRestaurant}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          agendaBlockId={agendaBlockId}
          isDesktopSize={sizeIsDesktop}
          total={totalSum}
        />
      )}
    </div>
  );
}
